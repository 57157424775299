import { useLocation } from "react-router-dom"

function Intro() {

const location = useLocation()
if (location.pathname !== "/") return <></> 
  return (
      <div id="intro">
        <h1>I'm Ska, make yourself at home</h1>
        <p>Im a full stack developer.</p>
        <ul className="actions">
          <li>
            <a
              href="#main"
              className="button icon solid solo fa-arrow-down scrolly"
            >
              Continue
            </a>
          </li>
        </ul>
      </div>
  )
}

export default Intro
