import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import { IconButton } from "@mui/material"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { serverURL } from ".."
import Markdown from "../PostsMarkDown/Markdown"

function Post() {
  const { url } = useParams()
  const id = url.slice(0, -1)
  const currentPart = url.slice(-1)

  const [post, setPost] = useState("")
  const [totalParts, setTotalParts] = useState()

  useEffect(() => {
    ;(async () => {
      let { status } = await fetch(
        `${serverURL}/blogs/${id}${+currentPart + 1}.md`
      )
      if (status === 404) setTotalParts(currentPart)
      else setTotalParts(+currentPart + 1)

      let file = await fetch(`${serverURL}/blogs/${url}.md`)
      setPost(await file.text())
    })()
  })

  return (
    <div className="post">
      <div id="top"></div>
      <IconButton className="goToTopFloat" href="#main" title="Go to top">
        <ArrowDropUpIcon  />
      </IconButton>
      {navigationArrows()}
      <h1>
        {id.replaceAll("-", " ") +
          (totalParts !== "1" ? "Part" + currentPart : "")}{" "}
      </h1>
      <Markdown className="markdown" key="1">
        {post}
      </Markdown>
      {navigationArrows()}
    </div>
  )

  function navigationArrows() {
    return (
      <div className="arrowNavigation">
        {/* Previous */}
        {currentPart !== "1" ? (
          <h2 style={{ textAlign: "right" }}>
            <Link to={`/blog/${id}${currentPart - 1}`}>◀ Previous part</Link>
          </h2>
        ) : (
          <div></div>
        )}

        {/* Next */}
        {currentPart !== totalParts && (
          <h2 style={{ textAlign: "right" }}>
            <Link to={`/blog/${id}${+currentPart + 1}`}>Next part ▶</Link>
          </h2>
        )}
      </div>
    )
  }
}

export default Post
