import React from "react"

function Project(props) {
  return (
    <article>
      <header>
        <h2>
          <a href={props.link}>
            {props.title}
            <br />
          </a>
        </h2>
      </header>
      <a href={props.link} className="image fit">
        <img src={"images/projectsThumbnail/" + props.image} alt="" />
      </a>
      <p>{props.text}</p>
      <ul className="actions special">
        <li>
          <a href={props.link} className="button">
            Website Link
          </a>
        </li>
      </ul>
    </article>
  )
}

export default Project
