import { Button, IconButton, Stack } from "@mui/material"
import ReactMarkdown from "markdown-to-jsx"
import { useRef, useState } from "react"
import { Link } from "react-router-dom"
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism"

const Code = ({ className, children }) => {
  const [open, setOpen] = useState(true)
  if (!className) return
  var fileName = ""
  const endMarker = children.indexOf("~~")
  if (endMarker > -1) {
    fileName = children.slice(0, endMarker)
    children = children.replace(fileName + "~~", "").trim()
  }

  const language = className.split("-")[1]
  const lines = children.split("\n").length

  return (
    <>
      <div className="arrowNavigation">
        {fileName ? <code className="fileName">{fileName}</code> : <div></div>}
        {lines > 10 && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            onClick={() => setOpen(!open)}
          >
            <label style={{ marginBottom: 0 }}>
              {open ? "Collapse" : "Expand"}
            </label>
            <IconButton sx={{ marginLeft: "auto" }}>
              {open ? (
                <ArrowDropUpIcon fontSize="large" />
              ) : (
                <ArrowDropDownIcon fontSize="large" />
              )}
            </IconButton>
          </Stack>
        )}
      </div>
      {/* <details open={open}>
        <summary></summary> */}
      <SyntaxHighlighter
        customStyle={{ maxHeight: open ? 500 : 100 }}
        language={language}
        style={materialDark}
      >
        {children}
      </SyntaxHighlighter>
      {/* </details> */}
    </>
  )
}

function RouterLink({ href, children }) {
  var containsAll = ["#", "http"].some((i) => href.includes(i))
  return containsAll ? (
    <a target={href.indexOf("#") === -1 ? "_blank" : ""} href={href}>
      {children}
    </a>
  ) : (
    <Link to={href}>{children}</Link>
  )
}

const options = {
  overrides: {
    code: {
      component: Code,
    },
    a: {
      component: RouterLink,
    },
  },
}

export default function Markdown(props) {
  return <ReactMarkdown options={options} {...props} />
}
