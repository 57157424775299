import { Grid } from "@mui/material"
import React from "react"
import FeaturedPost from "../components/Blog/FeaturedPost"
import MainFeaturedPost from "../components/Blog/MainFeaturedPost"
import posts from "../postsData"

function BlogPage() {
  return (
    <div>
     
      <MainFeaturedPost post={posts[0]} />
      <Grid container spacing={4}>
        {posts.slice(1).map((post) => (
          <FeaturedPost key={post.title} post={post} />
        ))}
      </Grid>
    </div>
  )
}

export default BlogPage
