import { Link, useLocation } from "react-router-dom"

import InstagramIcon from "@mui/icons-material/Instagram"
import GitHubIcon from "@mui/icons-material/GitHub"
function Navigation() {
  const location = useLocation()

  return (
    <div className="nav2">
      <ul className="links">
        {navLink({ path: "/projects", text: "My Projects" })}
        {navLink({ path: "/about_me", text: "About Me" })}
        {navLink({ path: "/blog", text: "Blog" })}
      </ul>
      <ul className="icons">
        {/* <li>
          <a href="#" className="icon brands fa-twitter">
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a href="#" className="icon brands fa-facebook-f">
            <span className="label">Facebook</span>
          </a>
        </li> */}
        <a
          href="https://www.instagram.com/twinunison/?hl=fr"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </a>
        <a
          href="https://github.com/SkaGitWork"
          target="_blank"
          rel="noreferrer"
        >
          <GitHubIcon />
        </a>
      </ul>
    </div>
  )
  function navLink({ path, text }) {
    return (
      <Link
        className={location.pathname === path ? "activeLink" : ""}
        to={path}
      >
        {text}
      </Link>
    )
  }
}

export default Navigation
