import { Button } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"
import { serverURL } from "../.."
import { formatTitle } from "../../functions"

function MainFeaturedPost({post}) {
  const { title, description } = post
  const formattedTitle = formatTitle(post.title)

  const image = `${serverURL}/blogPostImages/${formattedTitle}/thumbnail.png`

  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: "grey.800",
        color: "#fff",
        mb: 4,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${image})`,
      }}
    >
      {/* Increase the priority of the hero background image */}
      {
        <img
          style={{ display: "none" }}
          src={image}
          alt="Thumbnail"
        />
      }
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,.3)",
        }}
      />
      <Grid container>
        <Grid item md={6}>
          <Box
            sx={{
              // backgroundColor: "hsla(0, 0%, 0%,.1)",
              position: "relative",
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
            }}
          >
            <Typography
              component="h1"
              variant="h3"
              color="inherit"
              gutterBottom
            >
              {title}
            </Typography>
            <Typography variant="h5" color="inherit" paragraph>
              {description}
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#1E252D",
                "&:hover": {
                  //you want this to be the same as the backgroundColor above
                  backgroundColor: "hsl(212, 20%, 25%)",
                },
              }}
            >
              <Link
                style={{ color: "white" }}
                to={`${formattedTitle}-1`}
              >
                Continue reading...
              </Link>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default MainFeaturedPost
