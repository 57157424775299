
export default [
  {
    title: "YouTube MP3 downloader extension for chrome",
    description: "Learn how to create a Chrome extension with NodeJs server.",
  },
  {
    title: "My favorite VSCode Extensions for 2022",
    date: "Nov 17",
    description:
      "Discover all the best extensions I daily use for my web development.",
  },
  {
    title: "Post title",
    date: "Nov 11",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    image: "https://source.unsplash.com/random",
    imageLabel: "Image Text",
  },
]