import React from "react"
import InstagramIcon from "@mui/icons-material/Instagram"
import GitHubIcon from "@mui/icons-material/GitHub"
import { Stack, styled } from "@mui/system"

const SocialMedias = styled(Stack)({
  svg: {
    fontSize: 40,

    ":hover": {
      color: "#18bfef",
    },
  },
})

function AboutMe() {
  return (
    <div className="aboutMe">
      <h3>Who i'am :</h3>
      <p>
        Hello, I'm <strong>Ska</strong>, I'm born in Tunisia, I'm 22 years old,
        for 3 years I have been working as a Fullstack web developer in many
        sites. I have developed many websites and web applications and I seek to
        improve myself in the development of business and digitization.
      </p>

      <h3>My skills : </h3>
      <ul>
        <li>HTML, CSS, Javascript,Sass, ReactJs, NextJs, NodeJs.</li>
        <li>Adobe PhotoShop, Adobe Illustrator, Adobe Premiere</li>
        <li>AutoCad 2D </li>
      </ul>
      <Stack direction="row" justifyContent="end" spacing={2}>
        <a href="mailto:denguezli.iskander@gmail.com">
          denguezli.iskander@gmail.com
        </a>
        <SocialMedias direction="row" spacing={1}>
          <InstagramIcon />
          <GitHubIcon />
        </SocialMedias>
      </Stack>
    </div>
  )
}

export default AboutMe
