import React, { useEffect } from "react"
import { createRoot } from "react-dom/client"
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"
import Footer from "./components/Footer/Footer"
import Intro from "./components/Intro/Intro"
import Navigation from "./components/Navigation/Navigation"
import "./css/main.css"
import "./css/index.css"
import AboutMe from "./pages/AboutMe"
import BlogPage from "./pages/BlogPage"
import Post from "./pages/Post"
import Projects from "./pages/Projects"

const container = document.getElementById("root")
const root = createRoot(container)
export const serverURL = "http://localhost:4000"

const App = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div id="wrapper" className="fade-in">
            <div className="bg"></div> <Intro />
            <div id="main">
              <Navigation />

              <div style={{ padding: 16 }}>
                <Outlet />
              </div>
            </div>
            <Footer />
          </div>
        }
      >
        <Route path="/" element={<Projects />} />
        {/* Navigation */}
        <Route path="/projects" element={<Projects />} />
        <Route path="/about_me" element={<AboutMe />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:url" element={<Post />} />
      </Route>
    </Routes>
  )
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)
