import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { Link, useNavigate } from "react-router-dom"
import { serverURL } from "../.."
import { formatTitle } from "../../functions"

function FeaturedPost({ post }) {
  const { title, date, description } = post
  const navigate = useNavigate()

  const formattedTitle = formatTitle(title)

  const image = `${serverURL}/blogPostImages/${formattedTitle}/thumbnail.png`

  return (
    <Grid item xs={12} md={6}>
      <Card
        sx={{ display: "flex" }}
        onClick={() => navigate(`${formattedTitle}-1`)}
      >
        <CardContent sx={{ flex: 1 }}>
          <Typography component="h2" variant="h5">
            {title}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {date}
          </Typography>
          <Typography variant="subtitle1" paragraph>
            {description}
          </Typography>
          <Link to={`${formattedTitle}-1`}>
            <Typography variant="subtitle1" color="primary">
              Continue reading...
            </Typography>
          </Link>
        </CardContent>
        <CardMedia
          component="img"
          sx={{ width: 160, display: { xs: "none", sm: "block" } }}
          image={image}
          alt="Thumbnail"
        />
      </Card>
    </Grid>
  )
}

export default FeaturedPost
